import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import Lottie from 'lottie-react';
import RematesCard from './rematesCard';
import ovni from '../json/ovni.json';
import configuration from '../config/configuration.json';
import Preloader from '../components/Preloader';

class RematesList extends React.Component{
    state = {
        loading: true,
        error: null,
        data: [],
    }
    componentDidMount(){
        this.fetchData();
    }

    fetchData = async () =>{
        this.setState({loading: true})
        try{
            const daw = await fetch(process.env.REACT_APP_URL_API + configuration.GET_ALL_REMATES,{
                method: 'POST',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    "accept": "application/json"
                },
                mode: 'cors',
                cache: 'default',
                data: ''
            })
                .then((response)=> response.json())
                .then((data)=> {
                    console.log(data);
                    this.setState({data: data.data})
                })
                .then(()=>{
                    this.setState({loading: false})
                })
        }
        catch(error){
            console.error(error);
        }


    }
    render(){

        if(this.state.loading === true){
            return(
                <div className="lottie-loading">
                    <Preloader />
                </div>
            )
        }

        return (
            <div className="remates_list">
                <div className="breadcrum">
                <u><Link to="/">Inicio</Link></u> {" > "}
                    Reproductores
                </div>

                {this.state.data == false &&
                    (
                        <div className="lottie-loading">
                            <Lottie
                                animationData={ovni}
                                loop={true}
                                className="lottie-loading-anim"
                            />
                            <h4 className="textoConsola">
                                No se ha encontrado ningún remate activo.
                            </h4>
                        </div>
                    )
                }

                <div className="ordenRemates">
                    {this.state.data.map(card => (
                        <div className="orderFixed" style={{order: card.order}} key={card.id * 100}>
                            <RematesCard
                                key={card.id}
                                mi={card.id}
                                owner={card.owner}
                                start={card.start}
                                name={card.name}
                                end={card.end}
                                order={card.order}
                                files={ card.files }
                                img={card.previewImage}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default RematesList;
