import React from 'react';
import logoFooter from '../img/logo_footer.svg';
import facebook from '../img/facebook.png';
import youtube from '../img/youtube.png';
import instagram from '../img/instagram.png';
import twitter from '../img/twitter.svg';
import creadores from '../img/logo-creadores.png';
import phoneCall from '../img/phone-call.png';
import email from '../img/email.png';
import map from '../img/map.png';


const Footer = () => {

	return (
		<div>
			<div className="footer">

			<div>
					<h4 className="footer_title">Correo:</h4>
					<img className="footer_icon" src={email}></img>
					<span className="footer_text">elrodeo@elrodeo.com.py</span>
					<br/>
					<br/>
					<h4 className="footer_title">Teléfono:</h4>
					<img className="footer_icon" src={phoneCall}></img>
					<span className="footer_text">+595 981 577462</span>
				</div>


				<div className="footer-info-wrapper">
					<h4 className="footer_title">Oficina Comercial</h4>
					<img className="footer_icon" src={map}></img>
					<span className="footer_text"><a href="https://goo.gl/maps/EhMWjdksGJCgB4UC7">Ruta Transchaco KM 16,5 - Mariano Roque Alonso</a></span>
					<br/>
					<br/>
					<h4 className="footer_title">Oficina Administrativa</h4>
					<img className="footer_icon" src={map}></img>
					<span className="footer_text"><a href="https://goo.gl/maps/HfvSNfPUTXKMEkXN8">Republica Francesa 390 Esq. 25 de Mayo - Asunción</a></span>
				</div>

				<div className="newsletter-footer-block">
					<p className="newsletter-text">Se parte de nuestro Boletín Informativo</p>
					<a className="newsletter-button" href="/suscribir-boletin">Suscribirme</a>
				</div>

				<div className="footer_logo">
					<a href="https://www.facebook.com/elrodeoconsignataria" target="_blank">
						<img
							src={facebook}
							alt="facebook"
							className="navbar_rrss-icon-png"
						/>
					</a>
					<a href="https://www.instagram.com/elrodeosa/" target="_blank">
						<img
							src={instagram}
							alt="instagram"
							className="navbar_rrss-icon-png"
							/>
					</a>
					<a href="https://www.youtube.com/channel/UC5iWNZQrAOwZT6BqyoiDt8g/featured" target="_blank">
						<img
							src={youtube}
							alt="youtube"
							className="navbar_rrss-icon-png"
							/>
					</a>
				</div>


			</div>

			<div className="sub-footer">
				<p>Desarrollado por: </p><a href="https://creadores.com.py/" target="_blank"><img src={creadores}/></a>
			</div>

		</div>
	)
}

export default Footer;
