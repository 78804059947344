import React, { useState, useEffect } from 'react';

const LoteTimer = (props) => {
	const [active, setActive] = useState(false);
	const [days, setDays] = useState("");
	const [hours, setHours] = useState("");
	const [minutes, setMinutes] = useState("");
	const [seconds, setSeconds] = useState("");
	const [text, setText] = useState(null);
	const [style, setStyle] = useState("");
	const [loading, setLoading] = useState(false);
	const [initial, setInitial] = useState(false);
	let total = 0;

	function getDate() {

		let dateNow = new Date();
		let dateStart = new Date(props.start);
		let dateEnd = new Date(props.end);
		let duration = 0;

		let ms_s = 1000;
		let ms_m = ms_s * 60;
		let ms_h = ms_m * 60;
		let ms_d = ms_h * 24;

		if(dateNow - dateStart < 0) {
			duration = dateStart - dateNow;

			setText("COMIENZA EN: ");
			setStyle("#BDBDBD");
			setInitial(true);
			setActive(false);
		}

		else {
			if(dateNow - dateEnd < 0) {
				duration = dateEnd - dateNow;

				setText("TERMINA EN: ");
				setStyle("#66A38C");
				setActive(true);

			}
			else {
				duration = 0;
				setText("TERMINADO");
				setStyle("#F96161");
				setActive(false);
			}
		}

		let secsVar = Math.floor( (duration % ms_m) / ms_s );
		let minsVar = Math.floor( (duration % ms_h) / ms_m );
		let hoursVar = Math.floor( (duration % ms_d) / ms_h );

		setDays(Math.floor( duration / ms_d ));

		if(hoursVar < 10) {
			setHours(`0${hoursVar}`);
		}
		else {
			setHours(hoursVar);
		}

		if(minsVar < 10) {
			setMinutes(`0${minsVar}`);
		}

		else {
			setMinutes(minsVar);
		}

		if(secsVar < 10) {
			setSeconds(`0${secsVar}`);
		}

		else {
			setSeconds(secsVar);
		}

		total = hoursVar + minsVar + secsVar;
		if(total < 0) {
		}
	}

	useEffect(() => {
		setLoading(true);
		getDate();

		const anInterval = setInterval(
			() => {
				getDate();
			}, 1000);

		if(total < 0) {
			clearInterval(anInterval);
		}

		setLoading(false);

		return () => {
			clearInterval(anInterval);
		}

		}, []);

	useEffect(() => {
		props.updateStatus(active);
	},[active]);

	return (
		<div className="lote-status-bar"
			style={{ backgroundColor: style }}>
			{ !loading ?
				<>
					<div className="flex1"></div>
					<p>LOTE {props.name }</p>
					<div className="flex1">
						{ initial ?
							<div className="timer-wrapper align-right">
								<div className="timer-text">{text} {days} DÍAS. {hours}:{minutes}:{seconds}</div>
							</div>
							:
							active
								?
								<div className="timer-wrapper align-right">
									<div className="timer-text">{text} {days} DÍAS. {hours}:{minutes}:{seconds}</div>
								</div>
								:
								<div className="timer-wrapper align-right">
									<div className="timer-text">{ text }</div>
							</div>
						}
					</div>
				</>
				:
				<p>Cargando datos...</p>
			}
		</div>
	)

}

export default LoteTimer;
