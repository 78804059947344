import React, { useState } from 'react';
import {NavLink , Link} from 'react-router-dom';
import logo from '../img/logo_rodeo.svg';
import logoSmall from '../img/logo_rodeo-small.svg';
import hamburger from '../img/hamburger.png';
import hamburgerClose from '../img/cancel-icon.svg';
import Cookies from 'universal-cookie';
import UserMenu from '../components/UserMenu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OutsideClickHandler from 'react-outside-click-handler';
import PersonIcon from '@mui/icons-material/Person';
import { useMediaQuery } from 'react-responsive';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const Header = (props) => {

	const loggedIn = props.logged
	const cookies = new Cookies()
	const usuarioCookie = cookies.get('user') ? cookies.get('user')[0] : "?"
	const isMobileToDesktop = useMediaQuery({ minWidth: '20em', maxWidth: '68.74em' });

	const [userMenuButton, setUserMenuButton] = useState(false);
	const [hamburgerMenu, setHamburgerMenu] = useState(false);

	const handlerMenu = () => {
		setHamburgerMenu(!hamburgerMenu);
	}

	const userMenu = () => {
		setUserMenuButton(!userMenuButton);
	}

	const AccordionSummary = styled((props) => (
		<MuiAccordionSummary
			expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
			{...props}
		/>
	))(({ theme }) => ({
		backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, 0)',
		flexDirection: 'row',
		'& .MuiAccordionSummary-expandIconWrapper': {
			color: '#FFF',
		},
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(180deg)',
		},
		'& .MuiAccordionSummary-content': {
			flexGrow: 0
		},
	}));

	return (
		<div className="navbar_fixed">
			<div className="navbar developmentModel">

				<div className="navbar2_flex">
					<div className="navbar_logo navbar2_logo">
						<Link to="/">
							<img
								src={logo}
								className="navbar_logo_img"
								alt="El rodeo"
							/>
							<img
								src={logoSmall}
								className="navbar_logo_img-small"
								alt="El rodeo"
							/>
						</Link>
					</div>

					<div className="navbar2_menu_flex">
						{ isMobileToDesktop ?
							<div className="navbar2_menu_burger" onClick={handlerMenu}>
								<img
									src={ hamburgerMenu ? hamburgerClose : hamburger }
									alt="Menu"
									style={{width: "43px", marginTop: "-10px", marginRight:"-6px"}}
								/>
							</div>
						: null }

						<div className={hamburgerMenu ? "navbar2_menu navbar2_menu-active" : "navbar2_menu" }>

							<ul className="menu-ul-list">
								<li>
									<NavLink to="/" style={{textDecoration: "none"}}
										onClick={() => setHamburgerMenu(false)}
										className={({ isActive })  => (isActive ? 'activeReact' : 'inactiveReact')} end >
										Inicio
									</NavLink>
								</li>

								{ isMobileToDesktop ?
									<Accordion sx={{ backgroundColor: '#103e2d', color: 'white', boxShadow: 'none' }}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											sx={{ flexGrow: 0, marginBottom: '10px' }}
											aria-controls="panel1a-content"
											id="panel1a-header"
											>
										<Typography sx={{ width: '100%', flexShrink: 0 }}>La Empresa</Typography>
										</AccordionSummary>
										<AccordionDetails sx={{ padding: 0 }}>
										<Typography>
												<ul className="submenu-accordion">
													<li style={{ marginBottom: '10px' }}>
														<NavLink
															onClick={() => setHamburgerMenu(false)}
															to="/quienes-somos">Quiénes Somos</NavLink>
													</li>
													{ false && (
														<li>
															<NavLink
																onClick={() => setHamburgerMenu(false)}
																to="/preguntas-frecuentes">F.A.Q</NavLink>
														</li>
													)}
													<li>
														<NavLink
															onClick={() => setHamburgerMenu(false)}
															to="/contactanos">Contactos</NavLink>
													</li>
													<li>
														<NavLink
															onClick={() => setHamburgerMenu(false)}
															to="/terminos-y-condiciones">Términos y condiciones</NavLink>
													</li>
													<li>
														<NavLink
															onClick={() => setHamburgerMenu(false)}
															to="/habilitar-carpeta">Habilitar carpeta</NavLink>
													</li>
													<li>
														<NavLink
															onClick={() => setHamburgerMenu(false)}
															to="/trabaja-con-nosotros">Trabaja con nosotros</NavLink>
													</li>
												</ul>
											</Typography>
										</AccordionDetails>
									</Accordion>
									:
									<li class="li-submenu">
										<span className="litle-space-menu">La Empresa</span>
										<ExpandMoreIcon sx={{ fontSize: 15 }} />

										<ul className="submenu">
											<li>
												<NavLink
													onClick={() => setHamburgerMenu(false)}
													to="/quienes-somos">Quiénes Somos</NavLink>
											</li>
											{ false && (
												<li>
													<NavLink
														onClick={() => setHamburgerMenu(false)}
														to="/preguntas-frecuentes">F.A.Q</NavLink>
												</li>
											)}
											<li>
												<NavLink
													onClick={() => setHamburgerMenu(false)}
													to="/contactanos">Contactos</NavLink>
											</li>
											<li>
												<NavLink
													onClick={() => setHamburgerMenu(false)}
													to="/terminos-y-condiciones">Términos y condiciones</NavLink>
											</li>
											<li>
												<NavLink
													onClick={() => setHamburgerMenu(false)}
													to="/habilitar-carpeta">Habilitar carpeta</NavLink>
											</li>
											<li>
												<NavLink
													onClick={() => setHamburgerMenu(false)}
													to="/trabaja-con-nosotros">Trabaja con nosotros</NavLink>
											</li>
										</ul>
									</li>
								}

								<li>
									<NavLink
										onClick={() => setHamburgerMenu(false)}
										to="/reproductores" style={{textDecoration: "none"}}
										className={({ isActive })  => (isActive ? 'activeReact' : 'inactiveReact')} end >
										Reproductores
									</NavLink>
								</li>

								<li>
									<NavLink
									onClick={() => setHamburgerMenu(false)}
										to="/invernada" style={{textDecoration: "none"}}
										className={({ isActive }) => (isActive ? 'activeReact' : 'inactiveReact' )} end >
										Invernada
									</NavLink>
								</li>

								<li>
									{ false && (
									<NavLink
										onClick={() => setHamburgerMenu(false)}
										to="/consumo" style={{textDecoration: "none"}}
										className={({ isActive }) => (isActive ? 'activeReact' : 'inactiveReact' )} end >
										Consumo
									</NavLink>
									)}
									<a href="https://v1.elrodeo.com.py/feriaconsumo.php" style={{textDecoration: "none"}} target="_blank" rel="noreferrer" className="inactiveReact">
										Consumo
									</a>
								</li>

								<li>
									<NavLink
										onClick={() => setHamburgerMenu(false)}
										to="/frigorificos" style={{textDecoration: "none"}}
										className={({ isActive }) => (isActive ? 'activeReact' : 'inactiveReact' )} end >
										Frigoríficos
									</NavLink>
								</li>

								<li>
									<NavLink
										onClick={() => setHamburgerMenu(false)}
										to="/propiedades-rurales" style={{textDecoration: "none"}}
										className={({ isActive }) => (isActive ? 'activeReact' : 'inactiveReact' )} end >
										Propiedades Rurales
									</NavLink>
								</li>
							</ul>
						</div>

						{loggedIn
							?
							<OutsideClickHandler onOutsideClick={ () => setUserMenuButton(false) }>
								<div className="navbar2_avatar"
									onClick={ () => setUserMenuButton(!userMenuButton) }>
									{ usuarioCookie }
								</div>
								{ userMenuButton && (
									<UserMenu
										loggedIn={ loggedIn }
										userMenu={ userMenu }
										userName={ props.userName }
										lastName={ props.lastName }
										handleLogout={ props.handleLogout }/>
								)}
							</OutsideClickHandler>
							:

							<OutsideClickHandler onOutsideClick={ () => setUserMenuButton(false) }>
								<div className="navbar2_avatar navbar2_avatar-offline"
									onClick={ () => setUserMenuButton(!userMenuButton) }>
									<PersonIcon sx={{ fontSize: 30 }} />
								</div>
								{ userMenuButton && (
									<UserMenu
										loggedIn={ loggedIn }
										userMenu={ userMenu } />
								)}
							</OutsideClickHandler>
					}
					</div>

				</div>
				<div className="navbar_hamburger" id="navbar_hamburger">
					<img
						src={hamburger}
						alt="Menu"
						/>
				</div>
			</div>
		</div>
	)
}

export default Header;
