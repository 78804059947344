import React from 'react';
import whatsapp from '../img/whatsapp.svg';


const Whatsapp = () => {
	return(
		<div className="whatsapp">
			<a href="https://wa.me/+595981577462" target="_blank">
				<img
					src={whatsapp}
					alt="Whatsapp"
					className="navbar_rrss-icon"
				/>
			</a>
		</div>
	)
}

export default Whatsapp;
